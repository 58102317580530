import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-264a9c36"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "global-body"
};
import { ref, onMounted } from 'vue';
import contenet from './contenet/content.vue';
import { getstoreList } from '@/api/general';
import { getWorkerPerformance, getPerformance } from '@/api/performance';
export default {
  __name: 'index',

  setup(__props) {
    const generateFrom = () => {
      return {
        workerId: '',
        start_datetime: '',
        end_datetime: ''
      };
    };

    const formModel = ref(generateFrom());
    const contenetRef = ref();
    const userInfo = ref();
    const activeName = ref('上门师傅'); // 服务站chang事件

    const serveChange = e => {
      formModel.value.workerId = e[e.length - 1];
    };

    const handleClick = e => {
      activeName.value = e.props.name;
      restSubmit();
      getWorkerList();
    }; // 获取上门师傅列表内容


    const workerlistData = ref(); // 师傅列表内容

    const getWorkerList = async () => {
      try {
        var data = {
          sign: activeName.value,
          storeId: userInfo.value.sign === 'fwz' ? userInfo.value.storeId : null
        };
        const resData = await getstoreList(data);

        if (resData.status === 'success') {
          if (data.storeId) {
            var workerlist = resData.data[0].children[0].children;
            workerlistData.value = workerlist;
          } else {
            workerlistData.value = resData.data;
          }

          console.log('workerlistData-----》', workerlistData.value);
        } else {
          console.log('获取服务站数据失败了。。。');
        }
      } catch (err) {
        console.log(err);
      }
    };

    const dateArr = ref();

    const changeDate = dateArr => {
      if (dateArr && dateArr.length > 0) {
        formModel.value.start_datetime = dateArr[0];
        formModel.value.end_datetime = dateArr[1];
      } else {
        formModel.value.start_datetime = '';
        formModel.value.end_datetime = '';
      }
    }; // 查询
    // 上门师傅查询
    // const PerformanceData = ref()


    const WorkerSearch = async () => {
      try {
        var data = {
          workerId: formModel.value.workerId,
          start_datetime: formModel.value.start_datetime,
          end_datetime: formModel.value.end_datetime
        };
        const resData = await getWorkerPerformance(data);

        if (resData.status === 'success') {
          console.log(resData); // PerformanceData.value = resData.data

          contenetRef.value.showModal({
            data: resData.data,
            type: activeName.value
          });
        } else {
          console.log('获取服务站数据失败了。。。');
        }
      } catch (err) {
        console.log(err);
      }
    };

    const PerformanceSearch = async () => {
      try {
        var data = {
          fwz_id: formModel.value.workerId,
          start_datetime: formModel.value.start_datetime,
          end_datetime: formModel.value.end_datetime
        };
        const resData = await getPerformance(data);

        if (resData.status === 'success') {
          contenetRef.value.showModal({
            data: resData.data,
            type: activeName.value
          });
        } else {
          console.log('获取服务站数据失败了。。。');
        }
      } catch (err) {
        console.log(err);
      }
    }; // 搜索


    const onSubmit = () => {
      if (activeName.value === '上门师傅') {
        WorkerSearch();
      } else {
        PerformanceSearch();
      }
    }; // 重置


    const restSubmit = () => {
      formModel.value = generateFrom();
      dateArr.value = [];
      contenetRef.value.showModal({
        data: {},
        type: activeName.value
      });
    }; // 初始化


    const init = async () => {
      userInfo.value = JSON.parse(localStorage.getItem('userInfo'));
      await getWorkerList();
    };

    onMounted(() => {
      init();
    });
    return (_ctx, _cache) => {
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");

      const _component_el_tabs = _resolveComponent("el-tabs");

      const _component_el_cascader = _resolveComponent("el-cascader");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_date_picker = _resolveComponent("el-date-picker");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_tabs, {
        modelValue: activeName.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => activeName.value = $event),
        class: "demo-tabs",
        onTabClick: handleClick
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
          label: "上门师傅",
          name: "上门师傅"
        }), _createVNode(_component_el_tab_pane, {
          label: "售后",
          name: "售后"
        }), _createVNode(_component_el_tab_pane, {
          label: "销售",
          name: "销售"
        })]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_row, {
        class: "global-search"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form, {
            inline: true,
            model: formModel.value
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: activeName.value
            }, {
              default: _withCtx(() => [_createVNode(_component_el_cascader, {
                modelValue: formModel.value.workerId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formModel.value.workerId = $event),
                options: workerlistData.value,
                onChange: serveChange,
                clearable: "",
                style: {
                  "width": "260px"
                }
              }, null, 8, ["modelValue", "options"])]),
              _: 1
            }, 8, ["label"]), _createVNode(_component_el_form_item, {
              label: "下单时间"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_date_picker, {
                modelValue: dateArr.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => dateArr.value = $event),
                type: "daterange",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                format: "YYYY-MM-DD",
                "value-format": "YYYY-MM-DD HH:mm:ss",
                "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)],
                onChange: changeDate,
                style: {
                  "width": "260px"
                }
              }, null, 8, ["modelValue", "default-time"])]),
              _: 1
            }), _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [_createVNode(_component_el_button, {
                type: "primary",
                onClick: onSubmit
              }, {
                default: _withCtx(() => [_createTextVNode("确认")]),
                _: 1
              }), _createVNode(_component_el_button, {
                onClick: restSubmit
              }, {
                default: _withCtx(() => [_createTextVNode("重置")]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }, 8, ["model"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(contenet, {
        ref_key: "contenetRef",
        ref: contenetRef,
        onRefresh: onSubmit
      }, null, 512)]);
    };
  }

};