import request from './request'

// 获取6.12.1 服务站账号列表
export const fwzAccounts = (data) => {
  return request.post('/rame/gsfms/fwz_accounts', data)
}

// 服务站账号管理 - 权限配置
export const fwzgetMenus = (data) => {
  return request.post('/rame/gsfms/getMenus', data)
}

// 服务站账号管理 - 6.12.3 新增账号信息
export const addFwzAccounts = (data) => {
  return request.post('/rame/gsfms/add_fwz_accounts', data)
}

// 服务站账号管理 - 6.12.3 编辑账号信息
export const updateFwzAccounts = (data) => {
  return request.post('/rame/gsfms/update_fwz_accounts', data)
}

// 服务站账号管理 - 6.12.2 生成账号的推广二维码
export const addxcxCode = (data) => {
  return request.post('/rame/gsfms/getFWZ_xcx_code_url', data)
}

// 移动货架管理 - 6.12.2
export const getStocksCar = (data) => {
  return request.post('/rame/gsfms/stocks/getYDHJInfo', data)
}

//  6.3.5 引荐登录记录 /api/rame/gsfms/yjdl_records
export const getReferrerlogin = (data) => {
  return request.post('/rame/gsfms/yjdl_records', data)
}

// 6.3.6 引荐登录记录 - 查询明细
export const getReferrerDetail = (data) => {
  return request.post('/rame/gsfms/yjdl_records_detail', data)
}
// /rame/gsfms/financial_detail
// 6.3.6 引荐登录记录 - 查询明细
export const getfinancialDetail = (data) => {
  return request.post('/rame/gsfms/financial_detail', data)
}
