import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-07b6c202"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "global-body"
};
const _hoisted_2 = {
  class: "global-table"
};
import { ref, onMounted, reactive, computed } from 'vue';
import { getstoreList } from '@/api/general';
import { getReferrerlogin } from '@/api/account';
import { Refresh } from '@element-plus/icons-vue';
import dialogLook from './dialog/dialog-look.vue';
const defaultJob = '销售';
export default {
  __name: 'referrerlogin',

  setup(__props) {
    const joblist = [{
      value: '上门师傅',
      label: '上门师傅'
    }, {
      value: '销售',
      label: '销售'
    }, {
      value: '售后',
      label: '售后'
    }];

    const generateFrom = () => {
      return {
        sign: defaultJob,
        storeId: null,
        start_datetime: null,
        end_datetime: null
      };
    };

    const formModel = ref(generateFrom());
    const pagination = reactive({//   page_num: 1,
      //   page_size: 10,
      //   total: 0
    });
    const userInfo = ref();
    const jobconfirm = ref(defaultJob); // 确定后的岗位 用于显示隐藏输入框
    // 服务站列表

    const serveChange = e => {
      formModel.value.storeId = e[e.length - 1];
    };

    const StorelistData = ref([]); // 获取服务站列表数据

    const getStoreData = async () => {
      try {
        const resData = await getstoreList();

        if (resData.status === 'success') {
          StorelistData.value = resData.data;
        } else {
          console.log('获取服务站数据失败了。。。');
        }
      } catch (err) {
        console.log(err);
      }
    }; // 服务站选择


    const storeChange = val => {
      // console.log(val[val.length - 1])
      formModel.value.sign = val[val.length - 1];
    };

    const dateArr = ref();

    const changeDate = dateArr => {
      if (dateArr && dateArr.length > 0) {
        formModel.value.start_datetime = dateArr[0];
        formModel.value.end_datetime = dateArr[1];
      } else {
        formModel.value.start_datetime = '';
        formModel.value.end_datetime = '';
      }
    }; // 获取表格数据
    // 查询订单要传的所有参数


    const sendParams = computed(() => {
      return { ...pagination,
        ...formModel.value
      };
    });
    const loading = ref(false);
    const tableData = ref([]);

    const getTableData = async () => {
      loading.value = true;

      try {
        const resData = await getReferrerlogin(sendParams.value);

        if (resData.status === 'success') {
          tableData.value = resData.data;
          Object.assign(pagination, resData.paging);
        }

        loading.value = false;
      } catch (err) {
        console.log(err);
        loading.value = false;
      }
    }; // 页码变动
    // const handleCurrentChange = async (page) => {
    //   pagination.page_num = page
    //   await getTableData()
    // }


    const previewvisible = ref(false);
    const previewurl = ref(''); // 预览图片
    // 打开二维码

    const openQRcode = e => {
      console.log(e);
      previewurl.value = e;
      previewvisible.value = true;
    }; // 打开查看弹窗


    const dialogLookRef = ref(null);

    const dialogLookDiakog = e => {
      console.log('查看', e);
      dialogLookRef.value.showModal({
        orderInfo: e,
        formModel: formModel.value
      });
    }; // 搜索


    const onSubmit = () => {
      jobconfirm.value = formModel.value.sign;
      getTableData();
    }; // 重置


    const restSubmit = () => {
      formModel.value = generateFrom();
      dateArr.value = [];
      formModel.value.sign = jobconfirm.value;
      getTableData();
    }; // 设置默认服务站id


    const setServeId = async () => {
      if (userInfo.value.storeId) {
        formModel.value.storeId = userInfo.value.storeId;
      }

      getTableData();
    }; // 初始化


    const init = async () => {
      userInfo.value = JSON.parse(localStorage.getItem('userInfo'));
      await getStoreData();
      setServeId(); // 设置默认服务站id
    };

    onMounted(() => {
      init();
    });
    return (_ctx, _cache) => {
      const _component_el_cascader = _resolveComponent("el-cascader");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_date_picker = _resolveComponent("el-date-picker");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_tooltip = _resolveComponent("el-tooltip");

      const _component_el_divider = _resolveComponent("el-divider");

      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_avatar = _resolveComponent("el-avatar");

      const _component_icon_qrcode = _resolveComponent("icon-qrcode");

      const _component_a_button = _resolveComponent("a-button");

      const _component_el_link = _resolveComponent("el-link");

      const _component_el_table = _resolveComponent("el-table");

      const _component_a_image_preview = _resolveComponent("a-image-preview");

      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
        class: "global-search"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form, {
            inline: true,
            model: formModel.value
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "岗位"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_cascader, {
                modelValue: formModel.value.sign,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formModel.value.sign = $event),
                options: joblist,
                onChange: storeChange,
                style: {
                  "width": "260px"
                }
              }, null, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "服务站"
            }, {
              default: _withCtx(() => {
                var _userInfo$value;

                return [_createVNode(_component_el_cascader, {
                  modelValue: formModel.value.storeId,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formModel.value.storeId = $event),
                  options: StorelistData.value,
                  onChange: serveChange,
                  disabled: (_userInfo$value = userInfo.value) !== null && _userInfo$value !== void 0 && _userInfo$value.storeId ? true : false,
                  clearable: "",
                  style: {
                    "width": "260px"
                  }
                }, null, 8, ["modelValue", "options", "disabled"])];
              }),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "下单时间"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_date_picker, {
                modelValue: dateArr.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => dateArr.value = $event),
                type: "daterange",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                format: "YYYY-MM-DD",
                "value-format": "YYYY-MM-DD",
                "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)],
                onChange: changeDate,
                style: {
                  "width": "260px"
                }
              }, null, 8, ["modelValue", "default-time"])]),
              _: 1
            }), _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [_createVNode(_component_el_button, {
                type: "primary",
                onClick: onSubmit
              }, {
                default: _withCtx(() => [_createTextVNode("确认")]),
                _: 1
              }), _createVNode(_component_el_button, {
                onClick: restSubmit
              }, {
                default: _withCtx(() => [_createTextVNode("重置")]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }, 8, ["model"])]),
          _: 1
        })]),
        _: 1
      }), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
        style: {
          "margin-top": "10px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_tooltip, {
            class: "box-item",
            effect: "dark",
            content: "刷新",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: _unref(Refresh),
              circle: "",
              onClick: getTableData
            }, null, 8, ["icon"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_divider), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: tableData.value,
        style: {
          "width": "100%",
          "height": "70vh"
        },
        "empty-text": "暂无订单数据",
        fit: true
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: jobconfirm.value === '上门师傅' ? 'workerId' : 'id',
          label: "ID",
          fixed: "left",
          "min-width": _ctx.tableColumnwidth
        }, null, 8, ["prop", "min-width"]), jobconfirm.value === '售后' || jobconfirm.value === '销售' ? (_openBlock(), _createBlock(_component_el_table_column, {
          key: 0,
          prop: "account",
          label: "账号",
          fixed: "left",
          "min-width": _ctx.tableColumnwidth
        }, null, 8, ["min-width"])) : _createCommentVNode("", true), jobconfirm.value === '售后' || jobconfirm.value === '销售' ? (_openBlock(), _createBlock(_component_el_table_column, {
          key: 1,
          prop: "name",
          label: "持有者",
          fixed: "left",
          "min-width": _ctx.tableColumnwidth
        }, null, 8, ["min-width"])) : _createCommentVNode("", true), jobconfirm.value === '上门师傅' ? (_openBlock(), _createBlock(_component_el_table_column, {
          key: 2,
          prop: "empno",
          label: "员工编号",
          fixed: "left",
          "min-width": _ctx.tableColumnwidth
        }, null, 8, ["min-width"])) : _createCommentVNode("", true), jobconfirm.value === '上门师傅' ? (_openBlock(), _createBlock(_component_el_table_column, {
          key: 3,
          prop: "ename",
          label: "员工姓名",
          fixed: "left",
          "min-width": _ctx.tableColumnwidth
        }, null, 8, ["min-width"])) : _createCommentVNode("", true), jobconfirm.value === '上门师傅' ? (_openBlock(), _createBlock(_component_el_table_column, {
          key: 4,
          prop: "avatar",
          label: "头像",
          fixed: "left",
          "min-width": _ctx.tableColumnwidth
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_avatar, {
            size: "large",
            src: scope.row.avatar
          }, null, 8, ["src"])]),
          _: 1
        }, 8, ["min-width"])) : _createCommentVNode("", true), _createVNode(_component_el_table_column, {
          prop: "phone",
          label: "手机号",
          fixed: "left",
          "min-width": _ctx.tableColumnwidth
        }, null, 8, ["min-width"]), _createVNode(_component_el_table_column, {
          label: "二维码",
          fixed: "left",
          "min-width": _ctx.tableColumnwidth
        }, {
          default: _withCtx(scope => [scope.row.xcx_code_url ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            type: "outline",
            shape: "circle",
            onClick: $event => openQRcode(scope.row.xcx_code_url)
          }, {
            default: _withCtx(() => [_createVNode(_component_icon_qrcode)]),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["min-width"]), _createVNode(_component_el_table_column, {
          prop: "total_count",
          label: "数量",
          fixed: "left",
          "min-width": _ctx.tableColumnwidth
        }, null, 8, ["min-width"]), _createVNode(_component_el_table_column, {
          width: "200",
          label: "操作"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_link, {
            onClick: $event => dialogLookDiakog(scope.row),
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode("查看")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, loading.value]])])]), _createVNode(_component_a_image_preview, {
        src: previewurl.value,
        visible: previewvisible.value,
        "onUpdate:visible": _cache[3] || (_cache[3] = $event => previewvisible.value = $event)
      }, null, 8, ["src", "visible"]), _createVNode(dialogLook, {
        ref_key: "dialogLookRef",
        ref: dialogLookRef,
        onRestSubmit: getTableData
      }, null, 512)]);
    };
  }

};