import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
import { ref } from 'vue';
import { getReferrerDetail } from '@/api/account';
export default {
  __name: 'dialog-look',

  setup(__props, {
    expose: __expose
  }) {
    // defineEmits
    const generateFrom = () => {
      return {
        sign: '上门师傅',
        id: null,
        start_datetime: null,
        end_datetime: null
      };
    };

    const formModel = ref(generateFrom()); // defineEmits
    // const emit = defineEmits(['getSelectCarInfo'])

    const selectCarValue = ref(0); // 选择的车辆value值

    const tableData = ref([]); // 表格数据

    const loading = ref(false); // 表格加载状态

    const orderInfo = ref();
    const DialogVisible = ref(false);

    const showModal = data => {
      orderInfo.value = data.orderInfo;
      formModel.value.id = data.orderInfo.id;
      var search = data.formModel;
      const Keys = Object.keys(formModel.value);

      for (var key in search) {
        if (Keys.indexOf(key) > -1) {
          formModel.value[key] = search[key];
        }
      }

      selectCarValue.value = 0;
      getTableData();
      DialogVisible.value = true;
      console.log('获取到的订单数据search', search);
    }; // 获取表格数据


    const getTableData = async () => {
      loading.value = true;

      try {
        const resData = await getReferrerDetail(formModel.value);

        if (resData.status === 'success') {
          tableData.value = resData.data;
        } else {
          console.log('获取师傅数据失败了。。。');
        }
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    };

    const confirm = () => {
      // emit('getSelectCarInfo', selectCarInfo.value)
      DialogVisible.value = false;
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_table = _resolveComponent("el-table");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: DialogVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => DialogVisible.value = $event),
        title: "查看明细",
        "align-center": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: confirm
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        })])]),
        default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_table, {
          data: tableData.value,
          style: {
            "width": "100%",
            "height": "800px"
          },
          "empty-text": "暂无订单数据",
          fit: true
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            prop: "userId",
            label: "用户ID",
            fixed: "left",
            "min-width": _ctx.tableColumnwidth
          }, null, 8, ["min-width"]), _createVNode(_component_el_table_column, {
            prop: "mobilePhoneNumber",
            label: "手机号",
            fixed: "left",
            "min-width": _ctx.tableColumnwidth
          }, null, 8, ["min-width"]), _createVNode(_component_el_table_column, {
            prop: "createdAt",
            label: "创建时间",
            fixed: "left",
            "min-width": _ctx.tableColumnwidth
          }, null, 8, ["min-width"]), _createVNode(_component_el_table_column, {
            prop: "lastLoginIPInfo",
            label: "登录信息",
            fixed: "left",
            "min-width": _ctx.tableColumnwidth
          }, null, 8, ["min-width"])]),
          _: 1
        }, 8, ["data"])), [[_directive_loading, loading.value]])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};