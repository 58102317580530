import { renderSlot as _renderSlot, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-64223111"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "upload-container"
};
const _hoisted_2 = {
  key: 0,
  class: "loading-overlay"
};
const _hoisted_3 = {
  class: "el-upload__tip"
};
import { ref, reactive, computed } from 'vue';
import { ElMessage } from 'element-plus';
import { Loading } from '@element-plus/icons-vue';
export default {
  __name: 'fileUpload',
  props: {
    modelValue: [String, Object, Array],
    // 文件名称
    name: {
      type: String,
      default: 'excel'
    },
    // 文件大小限制 (单位：MB)
    fileSize: {
      type: Number,
      default: 10
    },
    // 支持的文件类型
    fileType: {
      type: Array,
      default: () => ['xlsx', 'pdf', 'docx', 'doc', 'xls', 'pptx', 'ppt']
    },
    // 是否禁用上传
    disabled: {
      type: Boolean,
      default: false
    },
    // 上传地址
    uploadUrl: {
      type: String,
      default: '/rame/gsfms/merge'
    },
    // 最大上传文件数
    limit: {
      type: Number,
      default: 5
    },
    // 是否支持多文件上传
    multiple: {
      type: Boolean,
      default: true
    },
    // 是否自动上传
    autoUpload: {
      type: Boolean,
      default: true
    },
    // ...其他参数
    // 开始时间
    start_datetime: {
      type: String,
      default: ''
    },
    end_datetime: {
      type: String,
      default: ''
    },
    // 上传的其他字段
    datafield: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:modelValue', 'on-success', 'beforeUpload'],

  setup(__props, {
    emit: __emit
  }) {
    const emits = __emit;
    const props = __props;
    const data = reactive({
      // uploadUrl: process.env.VUE_APP_BASE_API + props.uploadUrl, // 上传地址
      uploadUrl: computed(() => {
        let url = process.env.VUE_APP_BASE_API + props.uploadUrl;

        if (props.start_datetime) {
          url += `?start_datetime=${props.start_datetime}`;
        }

        if (props.end_datetime) {
          url += props.start_datetime ? `&end_datetime=${props.end_datetime}` : `?end_datetime=${props.end_datetime}`;
        }

        return url;
      }),
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token') // 请求头

      }
    });
    const uploadRef = ref(null);
    const fileList = ref([]); // 文件列表

    const accept = ref(props.fileType.map(type => `.${type}`).join(',')); // 接受的文件类型

    const loading = ref(false); // 加载状态
    // 上传成功回调

    const onSuccess = (response, file, fileList) => {
      // console.log('上传成功:', response, file, fileList)
      var excelName = response.data.excel_name;
      emits('update:modelValue', excelName);
      emits('on-success'); // 隐藏加载效果

      loading.value = false;
      ElMessage.success('上传成功！');
    }; // 上传失败回调


    const onError = (error, file, fileList) => {
      console.log('上传失败:', error, file, fileList); // 隐藏加载效果

      loading.value = false;
      ElMessage.error('上传失败，请重试！');
    }; // 文件移除回调


    const onRemove = (file, fileList) => {
      console.log('移除文件:', file, fileList);
    }; // 上传前校验


    const beforeUpload = file => {
      var nowaccept = file.name.split('.')[1];
      const isValidType = props.fileType.includes(nowaccept);
      const isValidSize = file.size / 1024 / 1024 < props.fileSize;

      if (!isValidType) {
        ElMessage.error(`文件类型不支持，仅支持 ${props.fileType.join(', ')} 格式`);
        return false;
      }

      if (!isValidSize) {
        ElMessage.error(`文件大小不能超过 ${props.fileSize}MB`);
        return false;
      } // 显示加载效果


      loading.value = true;
      emits('beforeUpload'); // 触发父组件的beforeUpload事件

      return true;
    };

    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_upload = _resolveComponent("el-upload");

      return _openBlock(), _createElementBlock(_Fragment, null, [_renderSlot(_ctx.$slots, "tipTop", {
        class: "uptitle"
      }), _createElementVNode("div", _hoisted_1, [loading.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_el_icon, null, {
        default: _withCtx(() => [_createVNode(_unref(Loading))]),
        _: 1
      })])) : _createCommentVNode("", true), _createVNode(_component_el_upload, {
        ref_key: "uploadRef",
        ref: uploadRef,
        class: "upload-demo",
        name: "excel",
        action: data.uploadUrl,
        headers: data.headers,
        data: __props.datafield,
        multiple: __props.multiple,
        limit: __props.limit,
        "file-list": fileList.value,
        "auto-upload": __props.autoUpload,
        "on-success": onSuccess,
        "on-error": onError,
        "on-remove": onRemove,
        "before-upload": beforeUpload,
        accept: accept.value
      }, {
        trigger: _withCtx(() => [_renderSlot(_ctx.$slots, "trigger", {}, () => [_createVNode(_component_el_button, {
          type: "primary",
          disabled: __props.disabled
        }, {
          default: _withCtx(() => [_createTextVNode("选择文件")]),
          _: 1
        }, 8, ["disabled"])])]),
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "tip", {}, () => [_createElementVNode("div", _hoisted_3, " 支持 " + _toDisplayString(__props.fileType.join(', ')) + " 格式文件，大小不超过 " + _toDisplayString(__props.fileSize) + "MB。 ", 1)])]),
        _: 3
      }, 8, ["action", "headers", "data", "multiple", "limit", "file-list", "auto-upload", "accept"])])], 64);
    };
  }

};