import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-54816189"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "global-body"
};
const _hoisted_2 = {
  class: "global-table"
};
import { reactive, ref, computed, onMounted, onBeforeMount } from 'vue';
import { getfinancialDetail } from '@/api/account';
import { getstoreList } from '@/api/general';
import { Refresh, Upload } from '@element-plus/icons-vue';
import dialogUploadFile from './dialog/dialog-UploadFile.vue';
const tableColumnwidth = 100;
export default {
  __name: 'financialDetail',

  setup(__props) {
    const tableRef = ref();
    const userInfo = ref(); // 表格内容

    const tableData = ref([]); // 查询订单可以传递的参数

    const loading = ref(false);

    const generateFrom = () => {
      return {
        userId: null,
        // 用户ID
        order_code: null,
        // 订单编号
        storeId: null,
        // 服务站
        trade_uuid: null,
        // 订单交易号
        start_datetime: null,
        end_datetime: null
      };
    };

    const dateArr = ref(); // 选择时间

    const changeDate = dateArr => {
      if (dateArr && dateArr.length > 0) {
        formModel.value.start_datetime = dateArr[0];
        formModel.value.end_datetime = dateArr[1];
      } else {
        formModel.value.start_datetime = '';
        formModel.value.end_datetime = '';
      }
    };

    const pagination = reactive({
      page_num: 1,
      page_size: 18,
      total: 0
    });
    const formModel = ref(generateFrom()); // 查询订单要传的所有参数

    const sendParams = computed(() => {
      return { ...pagination,
        ...formModel.value
      };
    }); // 选择服务站

    const storeChange = e => {
      console.log(e[1]);
      formModel.value.storeId = e[1];
    }; // 获取表格数据


    const getTableData = async () => {
      loading.value = true;

      try {
        sendParams.value.userId = Number(sendParams.value.userId);
        const resData = await getfinancialDetail(sendParams.value);

        if (resData.status === 'success') {
          if (formModel.value.exportOrdersExcel !== 1) {
            tableData.value = resData.data;
            Object.assign(pagination, resData.paging);
            formModel.value.exportOrdersExcel = null;
          } else {
            // 导出
            formModel.value.exportOrdersExcel = null;
          }
        }

        loading.value = false;
      } catch (err) {
        console.log(err);
        loading.value = false;
      }
    }; // 确认


    const onSubmit = () => {
      getTableData();
    };

    const StorelistData = ref([]); // 获取服务站列表数据

    const getStoreData = async () => {
      loading.value = true;

      try {
        const resData = await getstoreList();

        if (resData.status === 'success') {
          StorelistData.value = resData.data;
        } else {
          console.log('获取服务站数据失败了。。。');
        }

        loading.value = false;
      } catch (err) {
        console.log(err);
        loading.value = false;
      }
    }; // 重置


    const restSubmit = () => {
      if (userInfo.value.storeId) {
        // formModel.value = generateFrom1()
        formModel.value = generateFrom();
        formModel.value.storeId = userInfo.value.storeId;
      } else {
        formModel.value = generateFrom();
      }

      Object.assign(pagination, {
        page_num: 1,
        page_size: 18,
        total: 0
      });
      dateArr.value = '';
      getTableData();
    }; // 页码变动


    const handleCurrentChange = async page => {
      pagination.page_num = page;
      await getTableData();
    }; // 上传文件


    const dialogUploadFileRef = ref();

    const openUploadfile = data => {
      dialogUploadFileRef.value.showModal(data);
    }; // 设置默认服务站id


    const setServeId = () => {
      if (userInfo.value.storeId) {
        formModel.value.storeId = userInfo.value.storeId;
      }

      getTableData();
    }; // 初始化


    const init = async () => {
      await getStoreData();
      setServeId();
    };

    onMounted(() => {
      init();
    });
    onBeforeMount(() => {
      // 获取用户信息
      userInfo.value = JSON.parse(localStorage.getItem('userInfo'));
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_cascader = _resolveComponent("el-cascader");

      const _component_el_date_picker = _resolveComponent("el-date-picker");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_tooltip = _resolveComponent("el-tooltip");

      const _component_el_divider = _resolveComponent("el-divider");

      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_tag = _resolveComponent("el-tag");

      const _component_el_table = _resolveComponent("el-table");

      const _component_el_pagination = _resolveComponent("el-pagination");

      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
        class: "global-search"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form, {
            inline: true,
            model: _ctx.form
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "用户ID"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: formModel.value.userId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formModel.value.userId = $event),
                size: "large",
                placeholder: "请输入用户ID",
                clearable: "",
                style: {
                  "width": "260px"
                }
              }, null, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "订单编号"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: formModel.value.order_code,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formModel.value.order_code = $event),
                size: "large",
                placeholder: "请输入订单编号",
                clearable: "",
                style: {
                  "width": "260px"
                }
              }, null, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "服务站"
            }, {
              default: _withCtx(() => {
                var _userInfo$value;

                return [_createVNode(_component_el_cascader, {
                  modelValue: formModel.value.storeId,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formModel.value.storeId = $event),
                  options: StorelistData.value,
                  onChange: storeChange,
                  disabled: (_userInfo$value = userInfo.value) !== null && _userInfo$value !== void 0 && _userInfo$value.storeId ? true : false,
                  clearable: "",
                  style: {
                    "width": "260px"
                  }
                }, null, 8, ["modelValue", "options", "disabled"])];
              }),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "订单交易号"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: formModel.value.trade_uuid,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formModel.value.trade_uuid = $event),
                size: "large",
                placeholder: "请输入订单交易号",
                clearable: "",
                style: {
                  "width": "260px"
                }
              }, null, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "创建时间"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_date_picker, {
                modelValue: dateArr.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => dateArr.value = $event),
                type: "daterange",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                format: "YYYY-MM-DD",
                "value-format": "YYYY-MM-DD",
                "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)],
                onChange: changeDate,
                style: {
                  "width": "260px"
                }
              }, null, 8, ["modelValue", "default-time"])]),
              _: 1
            }), _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [_createVNode(_component_el_button, {
                type: "primary",
                onClick: onSubmit
              }, {
                default: _withCtx(() => [_createTextVNode("确认")]),
                _: 1
              }), _createVNode(_component_el_button, {
                onClick: restSubmit
              }, {
                default: _withCtx(() => [_createTextVNode("重置")]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }, 8, ["model"])]),
          _: 1
        })]),
        _: 1
      }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
        style: {
          "margin-top": "10px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_tooltip, {
            class: "box-item",
            effect: "dark",
            content: "刷新",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: _unref(Refresh),
              circle: "",
              onClick: getTableData
            }, null, 8, ["icon"])]),
            _: 1
          }), _createVNode(_component_el_tooltip, {
            class: "box-item",
            effect: "dark",
            content: "上传文件",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: _unref(Upload),
              circle: "",
              onClick: openUploadfile
            }, null, 8, ["icon"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_divider), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        ref_key: "tableRef",
        ref: tableRef,
        data: tableData.value,
        style: {
          "width": "100%"
        },
        "empty-text": "暂无订单数据"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "id",
          label: "ID",
          fixed: "left",
          "min-width": tableColumnwidth
        }), _createVNode(_component_el_table_column, {
          prop: "order_code",
          label: "订单编号",
          fixed: "left",
          "min-width": tableColumnwidth
        }), _createVNode(_component_el_table_column, {
          prop: "userId",
          label: "用户ID",
          fixed: "left",
          "min-width": tableColumnwidth
        }), _createVNode(_component_el_table_column, {
          prop: "storeId",
          label: "服务站ID",
          fixed: "left",
          "min-width": tableColumnwidth
        }), _createVNode(_component_el_table_column, {
          prop: "createdAt",
          label: "创建时间",
          fixed: "left",
          "min-width": tableColumnwidth
        }), _createVNode(_component_el_table_column, {
          prop: "trade_uuid",
          label: "订单交易号",
          fixed: "left",
          "min-width": "150"
        }), _createVNode(_component_el_table_column, {
          prop: "pay_status",
          label: "状态",
          fixed: "left",
          "min-width": tableColumnwidth
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_tag, {
            type: scope.row.pay_status === '已付款' ? 'success' : 'danger'
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(scope.row.pay_status), 1)]),
            _: 2
          }, 1032, ["type"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "amount",
          label: "交易金额",
          fixed: "left",
          "min-width": tableColumnwidth
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, loading.value]])]), _createVNode(_component_el_row, {
        justify: "end",
        class: "mt-20"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_pagination, {
          size: "small",
          background: "",
          layout: "prev, pager, next",
          class: "mt-4",
          "page-size": pagination.page_size,
          total: pagination.total,
          modelValue: pagination.page_num,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => pagination.page_num = $event),
          onCurrentChange: handleCurrentChange
        }, null, 8, ["page-size", "total", "modelValue"])]),
        _: 1
      }), _createVNode(dialogUploadFile, {
        ref_key: "dialogUploadFileRef",
        ref: dialogUploadFileRef
      }, null, 512)]);
    };
  }

};