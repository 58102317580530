import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-677d4a5c"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "global-body"
};
const _hoisted_2 = {
  class: "global-table"
};
import { reactive, ref, computed, onMounted } from 'vue';
import dayjs from 'dayjs';
import { getInvoiceLists, sendInvoice } from '@/api/invoice';
import modalEnitInvoice from './modal-editInvoice.vue'; // 修改发票弹窗

import dialoExamine from './modal-examine.vue'; // 审核弹窗

import { Refresh } from '@element-plus/icons-vue'; // 开票进度下拉框数据

export default {
  __name: 'index',

  setup(__props) {
    // eslint-disable-next-line no-unused-vars
    const progress = [{
      value: 1,
      label: '提交成功'
    }, {
      value: 2,
      label: '审核成功'
    }, {
      value: 3,
      label: '开票成功'
    }, {
      value: 4,
      label: '审核失败'
    }, {
      value: 5,
      label: '开票失败'
    }]; // 收票下拉框列表

    const sendlist = [{
      value: 1,
      label: '未发送'
    }, {
      value: 2,
      label: '发送至手机'
    }, {
      value: 3,
      label: '发送至邮箱'
    }, {
      value: 4,
      label: '发送至手机和邮箱'
    }]; // 表格内容

    const tableData = ref([]);
    const defaultTime = new Date(2000, 1, 1, 12, 0, 0); // 查询订单可以传递的参数

    const loading = ref(false);

    const generateFrom = () => {
      return {
        order_code: '',
        progress: '',
        send: '',
        start_datetime: '',
        // 开始时间
        end_datetime: '' // 结束时间

      };
    };

    const applyDateValue = ref('');
    const dateArr = ref();
    const pagination = reactive({
      page_num: 1,
      page_size: 10,
      total: 0
    });
    const formModel = ref(generateFrom()); // 查询订单要传的所有参数

    const sendParams = computed(() => {
      return { ...pagination,
        ...formModel.value
      };
    }); // 获取表格数据

    const getTableData = async () => {
      loading.value = true;

      try {
        const resData = await getInvoiceLists(sendParams.value);

        if (resData.status === 'success') {
          tableData.value = resData.data;
          Object.assign(pagination, resData.paging);
        }

        loading.value = false;
      } catch (err) {
        console.log(err);
        loading.value = false;
      }
    }; // 确认


    const onSubmit = () => {
      getTableData();
    }; // 重置


    const restSubmit = () => {
      formModel.value = generateFrom();
      Object.assign(pagination, {
        page_num: 1,
        page_size: 10,
        total: 0
      });
      dateArr.value = '';
      getTableData();
    }; // 页码变动


    const handleCurrentChange = async page => {
      pagination.page_num = page;
      await getTableData();
    }; // 打开审核信息弹窗


    const dialoExamineRef = ref();

    const openExamine = record => {
      dialoExamineRef.value.showModal(record);
    }; // 选择时间框确定


    const applyDateChange = e => {
      if (e) {
        const startDatetime = dayjs(e[0]).format('YYYY-MM-DD');
        const endDatetime = dayjs(e[1]).format('YYYY-MM-DD');
        formModel.value.start_datetime = startDatetime;
        formModel.value.end_datetime = endDatetime;
      } else {
        formModel.value.start_datetime = null;
        formModel.value.end_datetime = null;
      }
    }; // 打开修改发票弹窗


    const modalEnitInvoiceRef = ref();

    const editInvoice = record => {
      modalEnitInvoiceRef.value.showModal(record);
    }; // 发送发票


    const sendInvoiceFn = async record => {
      console.log(record);
      var data = {
        order_code: record.order_code,
        // 订单编号
        receive_mail: record.receive_mail,
        workerId: 2,
        // 暂时固定
        orderId: record.orderId,
        fp_type: record.fp_type,
        url: record.url
      };

      try {
        const resData = await sendInvoice(data);

        if (resData.status === 'success') {
          // 重新获取
          getTableData();
        }
      } catch (err) {
        console.log(err);
      }
    }; // 初始化


    const init = async () => {
      await getTableData();
    };

    onMounted(() => {
      init();
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_date_picker = _resolveComponent("el-date-picker");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_tooltip = _resolveComponent("el-tooltip");

      const _component_el_divider = _resolveComponent("el-divider");

      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_tag = _resolveComponent("el-tag");

      const _component_el_link = _resolveComponent("el-link");

      const _component_el_popconfirm = _resolveComponent("el-popconfirm");

      const _component_el_table = _resolveComponent("el-table");

      const _component_el_pagination = _resolveComponent("el-pagination");

      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
        class: "global-search"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form, {
            inline: true,
            model: _ctx.form
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "订单编号"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: formModel.value.order_code,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formModel.value.order_code = $event),
                size: "large",
                placeholder: "请输入订单编号",
                clearable: "",
                style: {
                  "width": "260px"
                }
              }, null, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "开票进度"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_select, {
                modelValue: formModel.value.progress,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formModel.value.progress = $event),
                placeholder: "请选择开票进度",
                size: "large",
                clearable: "",
                style: {
                  "width": "260px"
                }
              }, {
                default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(progress, item => {
                  return _createVNode(_component_el_option, {
                    key: item.value,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]);
                }), 64))]),
                _: 1
              }, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "收票状态"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_select, {
                modelValue: formModel.value.send,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formModel.value.send = $event),
                placeholder: "请选择收票状态",
                size: "large",
                clearable: "",
                style: {
                  "width": "260px"
                }
              }, {
                default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(sendlist, (item, index) => {
                  return _createVNode(_component_el_option, {
                    key: index,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]);
                }), 64))]),
                _: 1
              }, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "申请发票时间"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_date_picker, {
                modelValue: applyDateValue.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => applyDateValue.value = $event),
                type: "datetimerange",
                shortcuts: _ctx.shortcuts,
                "range-separator": "至",
                "start-placeholder": "开始时间",
                "end-placeholder": "结束时间",
                format: "YYYY-MM-DD",
                "default-time": _unref(defaultTime),
                onChange: applyDateChange,
                style: {
                  "width": "260px"
                }
              }, null, 8, ["modelValue", "shortcuts", "default-time"])]),
              _: 1
            }), _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [_createVNode(_component_el_button, {
                type: "primary",
                onClick: onSubmit
              }, {
                default: _withCtx(() => [_createTextVNode("确认")]),
                _: 1
              }), _createVNode(_component_el_button, {
                onClick: restSubmit
              }, {
                default: _withCtx(() => [_createTextVNode("重置")]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }, 8, ["model"])]),
          _: 1
        })]),
        _: 1
      }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
        style: {
          "margin-top": "10px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_tooltip, {
            class: "box-item",
            effect: "dark",
            content: "刷新",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: _unref(Refresh),
              circle: "",
              onClick: getTableData
            }, null, 8, ["icon"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_divider), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: tableData.value,
        style: {
          "width": "100%"
        },
        "empty-text": "暂无订单数据",
        fit: true
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "id",
          label: "发票ID",
          fixed: "left",
          width: "80"
        }), _createVNode(_component_el_table_column, {
          prop: "orderId",
          label: "订单ID",
          fixed: "left",
          width: "140"
        }), _createVNode(_component_el_table_column, {
          prop: "order_code",
          label: "订单编号",
          fixed: "left",
          width: "140"
        }), _createVNode(_component_el_table_column, {
          prop: "storeId",
          label: "开票服务站ID",
          fixed: "left",
          width: "180"
        }), _createVNode(_component_el_table_column, {
          prop: "workerId",
          label: "工作人员ID",
          fixed: "left",
          width: "180"
        }), _createVNode(_component_el_table_column, {
          label: "开票进度"
        }, {
          default: _withCtx(scope => [scope.row.progress == 1 ? (_openBlock(), _createBlock(_component_el_tag, {
            key: 0,
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode("提交成功")]),
            _: 1
          })) : _createCommentVNode("", true), scope.row.progress == 2 ? (_openBlock(), _createBlock(_component_el_tag, {
            key: 1,
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode("审核成功")]),
            _: 1
          })) : _createCommentVNode("", true), scope.row.progress == 3 ? (_openBlock(), _createBlock(_component_el_tag, {
            key: 2,
            type: "success"
          }, {
            default: _withCtx(() => [_createTextVNode("开票成功")]),
            _: 1
          })) : _createCommentVNode("", true), scope.row.progress == 4 ? (_openBlock(), _createBlock(_component_el_tag, {
            key: 3,
            type: "danger"
          }, {
            default: _withCtx(() => [_createTextVNode("审核失败")]),
            _: 1
          })) : _createCommentVNode("", true), scope.row.progress == 5 ? (_openBlock(), _createBlock(_component_el_tag, {
            key: 4,
            type: "danger"
          }, {
            default: _withCtx(() => [_createTextVNode("开票失败")]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "失败原因"
        }, {
          default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.cause), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "url",
          label: "发票链接"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_link, {
            type: "primary",
            href: scope.row.url
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(scope.row.url), 1)]),
            _: 2
          }, 1032, ["href"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "created_date",
          label: "开票时间"
        }), _createVNode(_component_el_table_column, {
          prop: "apply_date",
          label: "申请时间"
        }), _createVNode(_component_el_table_column, {
          prop: "update_date",
          label: "更新时间"
        }), _createVNode(_component_el_table_column, {
          label: "用户收票"
        }, {
          default: _withCtx(scope => [scope.row.send == 1 ? (_openBlock(), _createBlock(_component_el_tag, {
            key: 0,
            type: "danger"
          }, {
            default: _withCtx(() => [_createTextVNode("未发送")]),
            _: 1
          })) : _createCommentVNode("", true), scope.row.send == 2 ? (_openBlock(), _createBlock(_component_el_tag, {
            key: 1,
            type: "success"
          }, {
            default: _withCtx(() => [_createTextVNode("发送至手机")]),
            _: 1
          })) : _createCommentVNode("", true), scope.row.send == 3 ? (_openBlock(), _createBlock(_component_el_tag, {
            key: 2,
            type: "success"
          }, {
            default: _withCtx(() => [_createTextVNode("发送至邮箱")]),
            _: 1
          })) : _createCommentVNode("", true), scope.row.send == 5 ? (_openBlock(), _createBlock(_component_el_tag, {
            key: 3,
            type: "success"
          }, {
            default: _withCtx(() => [_createTextVNode("发送至手机和邮箱")]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "user_remark",
          label: "用户备注"
        }), _createVNode(_component_el_table_column, {
          prop: "worker_remark",
          label: "服务站操作人员备注",
          width: "180"
        }), _createVNode(_component_el_table_column, {
          label: "发票信息"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_link, {
            onClick: $event => editInvoice(scope.row),
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode("查看发票")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "操作"
        }, {
          default: _withCtx(scope => [scope.row.progress == 1 && scope.row.send == 1 || scope.row.progress == 4 && scope.row.send == 1 ? (_openBlock(), _createBlock(_component_el_link, {
            key: 0,
            type: "primary",
            onClick: $event => openExamine(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode(" 审核 ")]),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true), scope.row.progress == 2 && scope.row.send == 1 || scope.row.progress == 5 && scope.row.send == 1 ? (_openBlock(), _createBlock(_component_el_link, {
            key: 1,
            type: "primary",
            onClick: $event => openExamine(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode(" 开票 ")]),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true), scope.row.progress == 3 && scope.row.send == 1 ? (_openBlock(), _createBlock(_component_el_popconfirm, {
            key: 2,
            title: "确认发送发票？",
            onConfirm: $event => sendInvoiceFn(scope.row)
          }, {
            reference: _withCtx(() => [_createVNode(_component_el_link, {
              type: "primary"
            }, {
              default: _withCtx(() => [_createTextVNode(" 发送 ")]),
              _: 1
            })]),
            _: 2
          }, 1032, ["onConfirm"])) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, loading.value]])]), _createVNode(_component_el_row, {
        justify: "end",
        class: "mt-20"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_pagination, {
          size: "small",
          background: "",
          layout: "prev, pager, next",
          class: "mt-4",
          "page-size": pagination.page_size,
          total: pagination.total,
          modelValue: pagination.page_num,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => pagination.page_num = $event),
          onCurrentChange: handleCurrentChange
        }, null, 8, ["page-size", "total", "modelValue"])]),
        _: 1
      }), _createVNode(modalEnitInvoice, {
        ref_key: "modalEnitInvoiceRef",
        ref: modalEnitInvoiceRef,
        onRefresh: restSubmit
      }, null, 512), _createVNode(dialoExamine, {
        ref_key: "dialoExamineRef",
        ref: dialoExamineRef,
        onRefresh: restSubmit
      }, null, 512)]);
    };
  }

};