import request from './request'
// 获取上门师傅业绩数据
export const getWorkerPerformance = (data) => {
  return request.post('/rame/gsfms/yjgl/sf/getYJData', data)
}

// 获取销售售后业绩数据
export const getPerformance = (data) => {
  return request.post('/rame/gsfms/yjgl/sh_xs/getYJData', data)
}
